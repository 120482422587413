import { addDays as _addDays } from 'date-fns/addDays';
import { differenceInDays as _differenceInCalendarDays } from 'date-fns/differenceInDays';
import { differenceInYears as _differenceInYears } from 'date-fns/differenceInYears';
import { isExists as _isExists } from 'date-fns/isExists';
import { isFuture as _isFuture } from 'date-fns/isFuture';
import { isToday as _isToday } from 'date-fns/isToday';
import { isValid as _isValid } from 'date-fns/isValid';
import { isWithinInterval as _isWithinInterval } from 'date-fns/isWithinInterval';
import { subMonths as _subMonths } from 'date-fns/subMonths';

export { addDays } from 'date-fns/addDays';
export { addHours } from 'date-fns/addHours';
export { addWeeks } from 'date-fns/addWeeks';
export { addMonths } from 'date-fns/addMonths';
export { addYears } from 'date-fns/addYears';
export { compareAsc } from 'date-fns/compareAsc';
export { compareDesc } from 'date-fns/compareDesc';
export { eachDayOfInterval } from 'date-fns/eachDayOfInterval';
export { isAfter } from 'date-fns/isAfter';
export { isBefore } from 'date-fns/isBefore';
export { isEqual } from 'date-fns/isEqual';
export { isSameYear } from 'date-fns/isSameYear';
export { isSameMonth } from 'date-fns/isSameMonth';
export { isSameWeek } from 'date-fns/isSameWeek';
export { isToday } from 'date-fns/isToday';
export { startOfDay } from 'date-fns/startOfDay';
export { startOfWeek } from 'date-fns/startOfWeek';
export { startOfMonth } from 'date-fns/startOfMonth';
export { startOfYear } from 'date-fns/startOfYear';
export { endOfMonth } from 'date-fns/endOfMonth';
export { endOfYear } from 'date-fns/endOfYear';
export { getWeek } from 'date-fns/getWeek';
export { subYears } from 'date-fns/subYears';
export { subDays } from 'date-fns/subDays';
export { subWeeks } from 'date-fns/subWeeks';
export { subMonths } from 'date-fns/subMonths';

export { format, formatISO, formatShortISO } from './format';
export { parse, parseISO } from './parse';

export const DATE_FORMAT = 'dd-MM-yyyy';
export const DATE_FORMAT_ISO = 'yyyy-MM-dd';

type Part = number | string;

export const fromArgs = (year: Part, month: Part, day: Part): Date =>
  new Date(Number(year), Number(month) - 1, Number(day));

export const fromObject = (value: { year: Part; month: Part; day: Part }): Date =>
  fromArgs(value.year, value.month, value.day);

const isExists = (year: Part, month: Part, day: Part): boolean =>
  _isExists(Number(year), Number(month) - 1, Number(day));

type IsValid = {
  (date: string | undefined | null | Date): boolean;
  (year: Part, month: Part, day: Part): boolean;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValid: IsValid = (dateOrYear: any, month?: Part, day?: Part): boolean => {
  if (!dateOrYear) return false;
  if (month !== undefined && day !== undefined) return isExists(dateOrYear, month, day);
  if (dateOrYear instanceof Date) return _isValid(dateOrYear);
  if (typeof dateOrYear === 'string') {
    const [year, month, day] = dateOrYear.split('T')[0].split(/[-/]/);
    return isExists(year, month, day);
  }
  throw new Error('Invalid format to validate');
};

type IsFuture = {
  (year: Part, month: Part, day: Part): boolean;
};
export const isFuture: IsFuture = (year, month, day) => {
  return _isFuture(fromArgs(year, month, day));
};

export const isEighteenOrOlder = (year: Part, month: Part, day: Part) => {
  if (!year || !month || !day) return false;
  return yearsAgo(year, month, day) >= 18;
};

// Using start of day is more safe/correct for e.g. validations
export const daysFromNow = (days: number) => _addDays(new Date().setHours(0, 0, 0, 0), days);

export const differenceInDays = (left: string | Date, right?: string | Date) =>
  Math.abs(
    _differenceInCalendarDays(
      left instanceof Date ? left : new Date(left),
      right ? (right instanceof Date ? right : new Date(right)) : new Date(),
    ),
  );

const yearsAgo = (year: Part, month: Part, day: Part) => {
  return _differenceInYears(new Date(), fromArgs(year, month, day));
};

export const isLessThanYearAgo = (date: Date): boolean => {
  return _isWithinInterval(new Date(date), {
    start: _subMonths(Date.now(), 12),
    end: Date.now(),
  });
};

export {
  isTodayAmsterdam,
  formatAmsterdamDate,
  formatAmsterdamTime,
  formatShortISOAmsterdam,
  getAmsterdamHours,
} from './localized';
