import React from 'react';

import { Box, Heading } from '@sparky';

export const SelfservicePageTitle = ({ title }: { title: string }) => {
  return (
    <Box
      paddingLeft={{ initial: '2', sm: '0' }}
      paddingTop={{ initial: '10', md: '16' }}
      paddingBottom={{ initial: '8', md: '12' }}>
      <Heading as="h1" size={{ initial: 'S', sm: 'M', lg: 'L' }}>
        {title}
      </Heading>
    </Box>
  );
};
